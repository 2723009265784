<template>
  <background :useComplexBackground="useComplexBackground" :imageUrl="headerBackground">
    <header id="home" class="bg">
      <nav class="navbar navbar-expand-lg fixed-top dark-bg">
        <a class="navbar-brand" href="#">
          <div v-if="main.logo"><img src="../../assets/images/logo.png" alt="logo" id="Logo"></div>
          <div v-if="!main.logo">
            <span id="FirstName">{{main.name.first}}</span> <span id="LastName">{{main.name.last}}</span>
          </div>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"><i class="fas fa-bars" style="color:#fff; font-size:28px;"></i></span>
        </button>
        <div id="navbarNavAltMarkup" class="collapse navbar-collapse">
          <ul id="navbar-nav" class="navbar-nav ml-auto">
            <li class="active-nav">
              <!-- Replace href with router-link and use hash to navigate -->
              <router-link class="nav-item nav-link" :to="'/#home'">Home</router-link>
            </li>
            <li><router-link class="nav-item nav-link" :to="'/#about'">About</router-link></li>
            <li><router-link class="nav-item nav-link" :to="'/#skills'">Skills</router-link></li>
            <li><router-link class="nav-item nav-link" :to="'/#portfolio'">Portfolio</router-link></li>
            <li><router-link class="nav-item nav-link" :to="'/#contact'">Contact</router-link></li>
          </ul>
        </div>
      </nav>
      <Banner />
      <Arrow />
    </header>
  </background>
</template>

<script>
import Arrow from "../components/Arrow.vue";
import data from "../../data/data.json";
import Banner from "../components/Banner.vue";
import Background from "../components/Background.vue"; // Import the Background component
import headerBackground from '../../assets/images/header-background.jpg';

export default {
  name: "Header",
  components: {
    Banner,
    Arrow,
    Background, // Register the Background component
  },
  data() {
    return {
      main: data.main,
      useComplexBackground: true, // Toggle this to see if the background changes
      headerBackground, // Use the imported image
    };
  },
};
</script>

<style scoped>
header {
  min-height: 300px; /* Ensure the header is visible */
}
</style>
